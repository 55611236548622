import { graphql } from "gatsby"
import { motion } from "framer-motion"

import PageIntro from "../../components/PageIntro"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import ContactSection from "../../components/ContactSection"
import ImageDisplay from "../../components/ImageDisplay"
import Highlight from "../../components/Highlight"

function ServicesDisplay({ data }) {
  const images = data.allFile.edges

  const marketing = [
    {
      category: "Branding",
      heading: "Shape your brand.",
      id: "2",
      text: "Inspire loyalty with a unique brand that resonates with your vision and customers.",
      extraText: "",
      keywords: [
        "Brand identity",
        "Rebrands",
        "Packaging",
        "Logo design",
        "Style guide",
      ],
      link: "/services",
      cta: "Design your vision",
    },
  ]

  return (
    <>
      <ImageDisplay images={images} selection={["1"]} />
      {/* {
                marketing.map( service => 
                  <Highlight service={ service } images={ images } id={ service.id} />
                  )
              } */}

      <ImageDisplay images={images} selection={["3"]} />
      <ImageDisplay images={images} selection={["4"]} constrained />
      <ImageDisplay images={images} selection={["5"]} />
      <ImageDisplay images={images} selection={["6"]} constrained />
    </>
  )
}

const ServicesPage = ({ data }) => {
  return (
    <Layout navBarType="white">
      <Seo title="Services" />
      <PageIntro
        title="Galaxy Box"
        headline="Darkness never looked brighter."
      />
      <ServicesDisplay data={data} />
      <ContactSection />
    </Layout>
  )
}

export default ServicesPage

export const query = graphql`
  query GalaxyBoxPageQuery {
    allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        relativeDirectory: { eq: "galaxy-box" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData
          }
          name
        }
      }
    }
  }
`
